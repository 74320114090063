/* stylelint-disable */
@import '~slick-carousel/slick/slick.css'
@import '~slick-carousel/slick/slick-theme.css'
/* stylelint-enable */

colors = json('./styles/colors.json', { hash: true })

body[data-block-touch="true"]
	overflow: hidden

#root
	height: 100%

.ant-table-tbody > tr.ant-table-row-hover > td
.ant-table-tbody > tr.ant-table-row:hover > td
	background-color: #e8f4f5 !important

label.ant-radio-button-wrapper
	background-color: white

.full_width_tabs
	.ant-tabs-nav-list
		width: 99%

	.ant-tabs-nav-list > div
		display: flex
		align-items: center
		justify-content: center

	.ant-tabs-tab
		margin-right: 0
		padding: 8px 4px !important
		margin-left: 0!important
		width: 100%

.custom_tabs
	@extend .full_width_tabs

	.ant-tabs
	.ant-tabs-tabpane-active
		overflow: visible
		height: 100%

	.ant-tabs-content
	.ant-tabs-content-holder
		height: 100%

.no_margin
	.ant-tabs-nav-list
		background-color: white

	.ant-tabs-nav
		margin: 0

.custom_popover
	.ant-popover-arrow
		display: none

	.ant-popover-inner-content
		padding: 0
		overflow: hidden

	.ant-popover-inner
		overflow: hidden

.no_inner_padding_popover
	.ant-popover-inner
		padding: 0 !important

// Custom table pagination styles
.ant-pagination-item
.ant-pagination-prev
.ant-pagination-next
.ant-pagination-prev .ant-pagination-item-link
.ant-pagination-next .ant-pagination-item-link
	border: none !important
	text-align: center !important
	width: 32px !important
	height: 32px !important

.ant-pagination-item a
	color: #474747 !important
	font-size: 12px !important

.ant-pagination-prev a
.ant-pagination-next a
	border: none !important

.ant-pagination-item-active
	border-radius: 20px !important
	border: solid 1px colors.primary !important
	background-color: rgba(0, 132, 139, 0.08) !important

.ant-pagination-item-active a
	color: colors.primary !important
	font-size: 14px !important

.ant-pagination-item-ellipsis
	color: #bfbfbf !important
	font-size: 12px !important
	letter-spacing: 0.4px

.ant-pagination-options-size-changer .ant-select-selection
	background-color: #f5f5f5
	border: none
	font-size: 12px
	line-height: 1.33
	letter-spacing: 0.4px
	color: #595959
.ant-select-selection--single .ant-select-selection__rendered
	margin-right: 28px

.ant-tooltip-inner
	color: #ffffff
	background-color: #0d0d0d
	opacity: 0.9
.ant-tooltip-arrow-content
	--antd-arrow-background-color: #0d0d0d
	background-color: #0d0d0d
	opacity: 0.9

.custom_table
	.ant-spin-nested-loading
	.ant-table-content
		height: 100%

	.ant-spin-nested-loading > .ant-spin-container
	.ant-table-scroll
		height: 100%
		display: flex
		flex-direction: column

	.ant-table
	.ant-table-body
		flex: 1
		height: 1px
		overflow: auto

	.ant-table-tbody > tr.ant-table-row-hover > td
	.ant-table-tbody > tr.ant-table-row:hover > td
		background-color: #e8f4f5 !important

	.ant-table-tbody > tr.ant-table-row-selected td
		background-color: #e8f4f5 !important

	.ant-table-pagination
		align-self: flex-end

.responsive_table
	.ant-spin-nested-loading
	.ant-table-content
		height: 100%

	.ant-spin-nested-loading > .ant-spin-container
	.ant-table-container
	.ant-table-scroll
		height: 100%
		display: flex
		flex-direction: column

	.ant-table
	.ant-table-body
		flex: auto
		height: 1px
		position: relative

	.ant-table-pagination
		align-self: flex-end

span.custom-form-item-required
	&::before
		display: none
	&::after
		display: inline-block
		content: '*' !important
		color: #ff3b30
		padding-left: 2px

.ant-form-item
	margin-bottom: 16px

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)
	&::before
		display: none !important
	&::after
		display: inline-block
		content: '*' !important
		color: #ff3b30

// following styles are for the default modal
.ant-modal-content
	padding: 0 !important
	overflow: hidden
	// Safari specific fix on rendering overflow and border-radius - create a new stacking context
	transform: translateZ(0)

.ant-modal-header
	padding: 14px 28px !important
	border-bottom: 1px solid #e5ebf2 !important
	margin-bottom: 0 !important
	min-height: 52px // set min height so that the default antd close icon's positioning will be aligned
	display: flex
	align-items: center
	> * // since modal has flex display, set the width to 100%
		width: 100%

.ant-modal-body
	margin: 16px 28px !important

.ant-modal-footer:not(:empty)
	padding: 16px 28px !important
	border-top: 1px solid #e5ebf2 !important
	margin-top: 0 !important
	// buttons are aligned right
	display: flex
	justify-content: flex-end
	gap: 10px
	// overwrite antd's default margin between buttons in modal footer
	>.ant-btn+.ant-btn
		margin-inline-start: unset

// not using prefixCls so that we can build on top of the default styles above
.AnnouncementModal
	.ant-modal-body
		margin: 0 !important

.SpotCrackViewerModal
	.ant-modal-body
		margin: 0 !important
		border-top: 1px solid #e5ebf2 !important

// overwrite modal static method modal.info, used in downloadUtils.tsx
.ant-modal-confirm-info
	button
		background: colors.primary
		box-shadow: none
		&:focus-visible
			outline: none !important
		&:hover
			background: #189799 !important
	.anticon-exclamation-circle
		color: #189799 !important

[data-mode="mobile"]
	.ant-modal-footer
		padding: 12px 16px

.ant-dropdown
	text-align: left
	width: max-content

.custom_slider
	height: 100%
	.slick-list
	.slick-track
		height: 100%
	.slick-slide > div
		height: 100%
	.slick-prev
		left: 0
		width: 32px
		height: 32px
		z-index: 1
		background-color: rgba(13, 13, 13, 0.56)
		border-radius: 4px
	.slick-next
		right: 0
		width: 32px
		height: 32px
		z-index: 1
		background-color: rgba(13, 13, 13, 0.56)
		border-radius: 4px
	.slick-disabled
		display: none !important
	.slick-dots
		padding-bottom: 4px
	.slick-dots li
		margin: 0
		width: 16px
		height: 16px
	.slick-dots li button
		width: 16px
		height: 16px
	.slick-dots li button:before
		font-size: 8px
		color: #c4c4c4
		opacity: 1
		width: 16px
		height: 16px
	.slick-dots li.slick-active button:before
		color: colors.primary
		opacity: 1

.custom_slider_overflow_y_scroll
	@extend .custom_slider
	.slick-list
	.slick-track
		overflow-y: auto
	.slick-dots li.slick-active button:before
		color: colors.teal2

.custom_range_slider
	.ant-slider-handle
		width: 28px
		height: 28px
		margin-top: -9px
		box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12) !important
		border: none
		background: white
		border-radius: 100%
		&:after
		&:before
			content: none
	.ant-slider-track
		background: #28c9c6

.custom_time_picker
	.ant-picker
		background-color: rgba(118, 118, 128, 0.12)
		border: none
		box-sizing: border-box
		box-shadow: none
	.ant-picker:hover
	.ant-picker-focused
		border: 1px solid colors.primary
	.ant-picker-input
		> input
			line-height: 1
	.ant-picker-ok
		float: none
	.ant-picker-ranges
		display: flex
		align-items: center
		justify-content: center

.double_row_slider
	.slick-slide > div
		height: 50%

.ant-btn
	letter-spacing: 0.2px
	text-align: center

.ant-menu-item-divider:first-child
.ant-menu-item-divider:last-child
	display: none

.custom_search .ant-input-search-button
	display: none

.ant-select:not(.ant-select-customize-input) .ant-select-selector
	border: 1px solid #c5c5c7

.location_input
	> .ant-select-selector
		padding-left: 30px !important
		padding-right: 30px !important
	.ant-select-selection-search
		left: 30px !important
		right: 30px !important

.map_popup_overlay
	width: 560px
	> .ant-popover-content
		> .ant-popover-inner > .ant-popover-inner-content
			padding: 0
			width: 100%
		> .ant-popover-arrow
			display: none

.ant-btn[disabled]:not(.ant-btn-link[disabled])
.ant-btn-primary[disabled]
	border: none
	background-color: #bfbfbf
	color: white
	&:hover
		background-color: #bfbfbf
		color: white

.ant-btn-dangerous.ant-btn-primary[disabled]
	color: white
	background-color: #cecece

.ant-btn-background-ghost.ant-btn-primary[disabled]
	border: none
	background-color: #bfbfbf !important
	color: white !important
	&:hover
		background-color: #bfbfbf
		color: white

.input_remove_spin
	// Chrome, Safari, Edge, Opera
	input::-webkit-outer-spin-button
	input::-webkit-inner-spin-button
		-webkit-appearance: none
		margin: 0


	// Firefox
	input[type=number]
		-moz-appearance: textfield

.ant-collapse-header
	padding: 12px 16px !important

.ant-collapse-content-box
	border-top: 1px solid #b9b9bb !important

.custom_collapse
	.ant-collapse-content-box
		padding-left: 0
		padding-right: 0

	.ant-collapse-header
		padding: 0 0 0 20px !important
		opacity: 0.5

	.ant-collapse-arrow
		left: 0 !important

.custom_collapse1
	.ant-collapse-content-box
		padding-left: 0
		padding-right: 0
		border-top: none !important

	.ant-collapse-header
		padding: 0 0 0 2px !important
		color: #0d0d0d

.deny-message-manager-modal .ant-modal-confirm-btns
	display: flex
	justify-content: flex-end
	> button
		height: unset
		white-space: normal
		word-break: break-word
		max-width: 180px
		&:focus-visible
			outline: none

.ant-upload-picture-card-wrapper
	width: auto
	display: block

.ant-upload.ant-upload-select-picture-card
	width: 78px
	height: 70px
	margin-bottom: 16px

input[class*="ant-input"]:not(:focus)
textarea[class*="ant-input"]:not(:focus)
.ant-input-group-addon
	border-color: #c5c5c7 !important

input[class*="ant-input"]:focus
textarea[class*="ant-input"]:focus
	border-color: colors.primary !important

input[class*="ant-input"]:disabled:hover
textarea[class*="ant-input"]:disabled:hover
.ant-input-group-addon > div[style*="cursor: not-allowed"]:hover
	border-color: #b2b2b2 !important

.ant-input-search
	.ant-input-outlined:not([class*='focused'])
		border-color: #c5c5c7
	.ant-input-disabled:hover
		border-color: colors.surface_border_primary !important

// prefix class: custom-checkbox
.custom-checkbox-inner
	border-color: colors.primary !important
.custom-checkbox-checked
	.custom-checkbox-inner
		background-color: colors.primary !important

.ant-select-single
	height: auto

.ant-upload-drag
	border: none !important
	background-color: inherit !important

.ant-steps .ant-steps-item-finish .ant-steps-item-icon
	background-color: transparent

.ant-steps .ant-steps-item-wait .ant-steps-item-icon
	background-color: transparent
	border-color: #c5c5c7
	> .ant-steps-icon
		color: #c5c5c7

// workaround to align checkbox with first line of text especially if text is wrapped
#filterMultipleChoiceCheckbox .ant-checkbox-wrapper .ant-checkbox
	align-self: unset

// orange warning button
.warning_button
	background-color: colors.accent
	&:hover
		background-color: colors.accent_hover !important